import React from "react"
import ActesList from "../components/actes/ActesList.tsx"
import { Helmet } from "react-helmet";

const Home: React.FC<{}> = () => {

  return (
    <div className="w-full flex flex-col items-center justify-center text-customBlue mb-12">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Actes d'état civil en ligne | acte-naissance-en-ligne.fr</title>
        <meta name="description" content="Obtenez vos actes d'état civil en ligne facilement : acte de naissance, acte de mariage, ou acte de décès en quelques clics. Service rapide et simple." />
        <link rel="canonical" href="https://www.acte-naissance-en-ligne.fr/" />
      </Helmet>
      <div className="flex flex-col gap-3">
        <div className="text-md md:text-lg px-12 text-center"> Gagnez du Temps ! Commandez <strong> vos actes d'état civil en ligne </strong> en toute simplicité. </div>
        <ActesList />
        <div className="flex flex-col gap-3 px-12">
          <div> Accès à nos services 24/7, sans contrainte de déplacement. </div>
          <div>
            Ne perdez plus votre temps en mairie où les temps d’attente sont très longs et les horaires d’ouverture limités.
          </div>
          <div>
            Notre plateforme <a href="https://www.acte-naissance-en-ligne.fr" className="underline"> <strong> acte-naissance-en-ligne.fr </strong> </a>  vous offre la possibilité de <b> commander en ligne </b> vos actes <b> d'états civils </b> avec un focus principal sur les <strong> actes de naissances </strong>, les <strong> actes de mariage </strong> et les <strong> actes de décès </strong>.
          </div>
          <div>
            Nous vous accompagnons dans chaque étape du processus avec nos <b> formulaires intuitifs </b>.
          </div>
          <div>
            Vous n’avez plus qu’à renseigner les informations essentielles pour l’obtention de vos actes d’états civils et nous nous occupons du reste !
          </div>
          <div>
            Plus besoin de vous déplacer à plusieurs reprises pour constituer un dossier de mariage, obtenir vos papiers d’identité, entamer une démarche d’adoption, une demande de visa, une succession, un changement de nom…
          </div>
          <div>
            Gagnez un temps précieux et simplifiez vos <b> démarches administratives </b> avec notre plateforme de commande en ligne <a href="https://www.acte-naissance-en-ligne.fr" className="underline"> acte-naissance-en-ligne.fr</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home