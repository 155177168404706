import React, { useState } from "react"
import { useNavigate } from "react-router-dom";
import { useTypedSelector } from "../../store/index.ts";
import { IoMenu } from "react-icons/io5";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Header: React.FC<{}> = () => {

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate()
  const token = useTypedSelector(state => state.auth.token)

  const handleLinkClick = (to: string) => {
    setMenuOpen(false)
    navigate(to)
  }

  return (
    <div className="w-full relative bg-customBlue text-white flex flex-col-reverse md:flex-col p-6 text-lg">
      <IoMenu
        className="block md:hidden absolute right-1 top-1 text-4xl"
        onClick={() => { setMenuOpen(true) }}
      />
      <div className="hidden md:flex flex-col md:flex-row mx-auto md:mr-1 gap-2 text-base max-w-fit md:w-min whitespace-nowrap font-bold">
        {token && <div onClick={() => handleLinkClick('/dashboard')} > <div className="hover:-translate-y-1 cursor-pointer"> Dashboard </div> </div>}
        <div onClick={() => handleLinkClick("/acte-de-naissance#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de naissance </div> </div>
        <div onClick={() => handleLinkClick("/acte-de-mariage#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de marriage </div> </div>
        <div onClick={() => handleLinkClick("/acte-de-deces#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de décès </div> </div>
        <div onClick={() => handleLinkClick("/demarche")} > <div className="hover:-translate-y-1 cursor-pointer"> Vos démarches </div> </div>
        <div onClick={() => handleLinkClick("/recherche-mairie")} > <div className="hover:-translate-y-1 cursor-pointer"> Info mairie </div> </div>
        <div onClick={() => handleLinkClick("/contact")} > <div className="hover:-translate-y-1 cursor-pointer"> Contact </div> </div>
      </div>
      <div className="flex flex-col md:text-start p-4">
        <div onClick={() => handleLinkClick('/')} > <div className="font-bold text-2xl md:text-3xl cursor-pointer"> acte-naissance-en-ligne.fr </div> </div>
        <div className="font-light text-sm"> Assistance administrative privée indépendante des administrations </div>
      </div>

      {
        menuOpen && (
          <div className="fixed inset-2 bg-customBlue h-screen rounded border z-30">
            <IoMdCloseCircleOutline
              className="ml-auto mr-2 mt-2 text-3xl"
              onClick={() => { setMenuOpen(false) }}
            />
            <div className="flex flex-col gap-4 ml-6">
              {token && <div onClick={() => handleLinkClick('/dashboard')} > <div className="hover:-translate-y-1 cursor-pointer"> Dashboard </div> </div>}
              <div onClick={() => handleLinkClick("/acte-de-naissance#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de naissance </div> </div>
              <div onClick={() => handleLinkClick("/acte-de-mariage#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de marriage </div> </div>
              <div onClick={() => handleLinkClick("/acte-de-deces#mainForm")} > <div className="hover:-translate-y-1 cursor-pointer"> Acte de décès </div> </div>
              <div onClick={() => handleLinkClick("/demarche")} > <div className="hover:-translate-y-1 cursor-pointer"> Vos démarches </div> </div>
              <div onClick={() => handleLinkClick("/recherche-mairie")} > <div className="hover:-translate-y-1 cursor-pointer"> Info mairie </div> </div>
              <div onClick={() => handleLinkClick("/contact")} > <div className="hover:-translate-y-1 cursor-pointer"> Contact </div> </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Header;
